<template>
  <v-container>
    <v-row align="center" justify="center" style="height: 70vh;">
      <v-progress-circular indeterminate color="primary" />
    </v-row>
  </v-container>
</template>

<script>
import { RepoFactory } from '@/core/repositories';
import { ROUTE_NAMES } from './route';
import FlightsMixins from '@/modules/flights/mixins/FlightsMixins';
import EnvUtils from '@/core/utils/environment';
import * as Sentry from '@sentry/vue';

export default {
  name: 'ShopFlightRedirectPage',
  mixins: [FlightsMixins],
  props: {
    flightNumber: {
      type: String,
      default: '',
    },
    originCode: {
      type: String,
      default: '',
    },
    year: {
      type: String,
      default: '',
    },
    month: {
      type: String,
      default: '',
    },
    day: {
      type: String,
      default: '',
    },
  },
  computed: {
    hasFlightNumber() {
      return !!this.flightNumber;
    },
    hasOrigin() {
      return !!this.originCode;
    },
    hasDate() {
      return !!this.year && !!this.month && !!this.day;
    },
    flightNumberIata() {
      return this.hasFlightNumber ? this.flightNumber : '';
    },
    origin() {
      return this.hasOrigin ? this.originCode : '';
    },
    departureDate() {
      return this.hasDate ? `${this.year}-${this.month}-${this.day}` : '';
    },
    departureYear() {
      return this.selectedContextFlight
        ? new Date(this.selectedContextFlight.departureTime)
            .getFullYear()
            .toString()
        : '';
    },
    departureMonth() {
      if (!this.selectedContextFlight) return '';
      const month =
        new Date(this.selectedContextFlight.departureTime).getMonth() + 1;
      return month.toString();
    },
    departureDay() {
      return this.selectedContextFlight
        ? new Date(this.selectedContextFlight.departureTime)
            .getDate()
            .toString()
        : '';
    },
    conciergeShopPathV4FF() {
      return this.$store.state.isShopV4;
    },
  },
  beforeRouteEnter(to, from, next) {
    return next((vm) => {
      if (vm.hasSelectedContextFlight) {
        EnvUtils.redirectToWPNuxt(
          vm.$pathPrefix('/shop', vm.conciergeShopPathV4FF ? '/v4' : '/v3'),
          {
            flightNumber: vm.selectedContextFlight.flightNumber,
            flightDate: vm.selectedContextFlight.departureTime,
          }
        );
      } else {
        EnvUtils.redirectToWPNuxt(
          vm.$pathPrefix('/shop', vm.conciergeShopPathV4FF ? '/v4' : '/v3')
        );
      }
    });
  },
  watch: {
    flightNumber: {
      immediate: true,
      async handler(newFlightNumber, oldFlightNumber) {
        let flight = null;
        if (!!newFlightNumber) {
          flight = await this.getFlight(
            newFlightNumber,
            this.origin,
            this.departureDate
          );
          if (!!flight && flight.id) {
            this.addFlightsToContext([flight]);
            const store = await this.getStoreFromFlight(flight.id);
            if (!!store) {
              this.redirectToStoreFromFlight(store.id);
            } else {
              this.redirectTo404();
            }
          } else this.redirectTo404();
        }
      },
    },
    $route: {
      immediate: true,
      handler(val, oldVal) {
        if (val && val.name == 'shop' && this.hasSelectedContextFlight) {
          this.$router.replace({
            name: ROUTE_NAMES.SHOP_FLIGHT_REDIRECT_LANDING,
            params: {
              flightNumber: this.selectedContextFlight.flightNumber,
              originCode: this.selectedContextFlight.origin.iata,
              year: this.departureYear,
              month: this.departureMonth,
              day: this.departureDay,
            },
            query: { ...val.query },
          });
        } else if (!Boolean(val?.params?.flightNumber)) {
          this.$router.replace('/concierge/discover');
        }
      },
    },
  },
  methods: {
    async getFlight(flightNumber, origin, departureDate) {
      const flightRepo = RepoFactory.get('flight');
      try {
        const flightsResponse = await flightRepo.getFlights({
          flightNumber,
          departureDate,
        });
        const matchingFlight = flightsResponse.data.find(
          (f) => f.origin.iata.toLowerCase() === origin.toLowerCase()
        );
        if (matchingFlight == null || matchingFlight.id == null) {
          throw 'No matching flight';
        }
        this.setSelectedFlight(matchingFlight);
        return matchingFlight;
      } catch (err) {
        Sentry.captureException(err);
        this.redirectTo404();
      }
    },

    async getStoreFromFlight(flightId) {
      const storeRepo = RepoFactory.get('shop');
      try {
        const stores = await storeRepo.getStores([flightId]);
        const matchingStore = stores.find(
          (s) => s.storeDetails.details.id == flightId
        );

        if (matchingStore == null) {
          throw 'No matching store';
        }
        return matchingStore;
      } catch (err) {
        this.redirectTo404();
      }
    },
    redirectToStoreFromFlight(storeId) {
      this.$router.replace({
        name: ROUTE_NAMES.SHOP_LANDING,
        params: { id: storeId },
        query: { ...this.$route.query },
      });
    },
    redirectTo404() {
      this.$router.push('/404').catch((err) => {});
    },
  },
};
</script>

<style scoped></style>
